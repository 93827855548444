import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";
import 'jquery-confirm';
import Rails from '@rails/ujs';
import Alpine from "alpinejs";
import { Turbo } from "@hotwired/turbo-rails";
import '../vendor/jquery.validate.min';

window.Alpine = Alpine;
$(() => {
    Alpine.start();
});
Rails.start();
Turbo.session.drive = false;
import * as Popper from "@popperjs/core";
import "./navbar";
import "./stripe_resources";
import "alpine-turbo-drive-adapter";
import "active_storage_drag_and_drop";
import "./cocoon";
import "./cocoon-nested-field-limit";
import { createPopup } from "@picmo/popup-picker";
import "easy-autocomplete";
import "@fortawesome/fontawesome-free";
import "./bi-one";
import "./moment-parse"
import "./fa-five";
import tippy from 'tippy.js';
import "./css/application.scss"
import moment from "moment"
import ClipboardJS from "./clipboard";
window.moment = moment;
window.Popper = Popper;

window.addEventListener("load", function(event) {
    window.dataLayer = window.dataLayer || []
    function gtag(){dataLayer.push(arguments)}
    gtag('js', new Date())
    gtag('config', 'G-8VXWTS1HRG', {'page_location': window.location.href})
}, false)

tippy("#mobile_service", {
    theme: "custom",
    arrow: true,
    animation: 'fade',
    content: "Services the client on premise or location of appointment."
});

const emoji = document.querySelector("#selection-emoji");

const picker = createPopup(
    {},
    {
        referenceElement: document.querySelector("#chat_message"),
        triggerElement: emoji,
        position: "bottom-start",
        showCloseButton: false
    }
);

emoji?.addEventListener("click", () => {
    picker.toggle();
});

function insertAtCaret(areaId, text) {
    var txtarea = document.getElementById(areaId);
    if (!txtarea) {
        return;
    }

    var scrollPos = txtarea.scrollTop;
    var strPos = 0;
    var br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ?
        "ff" : (document.selection ? "ie" : false));
    if (br == "ie") {
        txtarea.focus();
        var range = document.selection.createRange();
        range.moveStart('character', -txtarea.value.length);
        strPos = range.text.length;
    } else if (br == "ff") {
        strPos = txtarea.selectionStart;
    }

    var front = (txtarea.value).substring(0, strPos);
    var back = (txtarea.value).substring(strPos, txtarea.value.length);
    txtarea.value = front + text + back;
    strPos = strPos + text.length;
    if (br == "ie") {
        txtarea.focus();
        var ieRange = document.selection.createRange();
        ieRange.moveStart('character', -txtarea.value.length);
        ieRange.moveStart('character', strPos);
        ieRange.moveEnd('character', 0);
        ieRange.select();
    } else if (br == "ff") {
        txtarea.selectionStart = strPos;
        txtarea.selectionEnd = strPos;
        txtarea.focus();
    }

    txtarea.scrollTop = scrollPos;
}

picker.addEventListener("emoji:select", (selection) => {
    insertAtCaret("chat_message", selection.emoji);
});

$(document).delegate('#service_profile_add_ons', 'cocoon:before-remove', function(e, item) {
    var conf;
    if (item) {
       conf = confirm('Are you sure you want to delete this add-on?');
    }

    if (conf) {
        $(this).data('remove-timeout', 1000);
             item.fadeOut('slow', function() {item.remove()});
            return true;
        } else {
            return false
    }
});


$(document).delegate('#service_profile_protocols', 'cocoon:before-remove', function(e, item) {
    var conf;
    if (item) {
        conf = confirm('Are you sure you want to delete this service protocol?');
    }

    if (conf) {
        $(this).data('remove-timeout', 1000);
        item.fadeOut('slow', function() {item.remove()});
        return true;
    } else {
        return false
    }
});

$(document).delegate('#service_profile_languages', 'cocoon:before-remove', function(e, item) {
    var conf;
    if (item) {
        conf = confirm('Are you sure you want to delete this language?');
    }

    if (conf) {
        $(this).data('remove-timeout', 1000);
        item.fadeOut('slow', function() {item.remove()});
        return true;
    } else {
        return false
    }
});

$(document).delegate('#service_profile_protocol_rules', 'cocoon:before-remove', function(e, item) {
    var conf;
    if (item) {
        conf = confirm('Are you sure you want to delete this rule?');
    }

    if (conf) {
        $(this).data('remove-timeout', 1000);
        item.fadeOut('slow', function() {item.remove()});
        return true;
    } else {
        return false
    }
});

$(document).delegate('#service_counties', 'cocoon:before-remove', function(e, item) {
    var conf;
    if (item) {
        conf = confirm('Are you sure you want to delete this county?');
    }

    if (conf) {
        $(this).data('remove-timeout', 1000);
        item.fadeOut('slow', function() {item.remove()});
        return true;
    } else {
        return false
    }
});


// activate token input for service counties
var options = {
    url: function(phrase) {
        return "/service_county_search/?q=" + phrase;
    },
    getValue: "name",
    list: {
        match: {
            enabled: true
        },
        showAnimation: {
            type: "fade", //normal|slide|fade
            time: 400,
            callback: function() {}
        },

        hideAnimation: {
            type: "slide", //normal|slide|fade
            time: 400,
            callback: function() {}
        }
    },
    theme: "round"
};

$(document).delegate('#service_counties', 'cocoon:after-insert', function(e, item) {
    $('*[data-behavior="autocomplete"]').easyAutocomplete(options);
});

// TOS Checkbox Code
$(function() {
    let tos = $("#user_tos_agreement");
    let signupSubmitBtn = $("#signup_submit_btn");

    tos.change(function() {
        signupSubmitBtn.prop("disabled", !$(this).is(':checked'))

        if($(this).is(':checked')) {
            signupSubmitBtn.removeClass("opacity-50")
        } else {
            signupSubmitBtn.addClass("opacity-50")
        }
    });

    //for initial case
    tos.trigger('change');
});

if($('#copy-button').length > 0) {
    //Inititalize ClipboardJS
    new ClipboardJS('.btnClipboardJS');

// SET data-clipboard-text TO CURRENT URL ON PAGE LOAD
    window.onload = function currentURL() {
        const URL = window.location.href;
        document.getElementById('copy-button').innerHTML = '<a onClick="copiedToClipboard()" style="border: 1px lightslategray solid; padding: 2px; border-radius: 4px; cursor: pointer;" class="btnClipboardJS" data-clipboard-text="' + URL + '" id="alertCard"><i class="fa fa-link" aria-hidden="true"></i> Copy Link</a>'
    }

// If you only want the functionality, but not the visual effect, ignore the following function.

// AESTHETIC: CHANGE INNER HTML
    window.copiedToClipboard = () => { document.getElementById('alertCard').innerHTML = '<i class="fa fa-check" style="color: green;" aria-hidden="true"></i> Link Copied!';
        setTimeout(function(){document.getElementById('alertCard').innerHTML = '<i class="fa fa-link" aria-hidden="true"></i> Copy Link Again';}, 3000);
    }
}
